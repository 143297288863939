"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingInView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var SingIn_1 = require("@web/components/SingIn");
var Page_1 = require("./layout/Page");
var SingInView = function () {
    return ((0, jsx_runtime_1.jsx)(Page_1.Page, { children: (0, jsx_runtime_1.jsx)(SingIn_1.SingIn, {}) }));
};
exports.SingInView = SingInView;
