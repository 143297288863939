"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppRoutes = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var User_1 = require("@web/contexts/User");
var routers_1 = require("@web/routers");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var AppRoutes = function () {
    var currentAccount = (0, react_1.useContext)(User_1.UserContext).currentAccount;
    return ((0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [routers_1.appRoutersList.map(function (_a) {
                var url = _a.url, Component = _a.component, ComponentWrapper = _a.componentWrapper;
                return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: url, element: ComponentWrapper ? ((0, jsx_runtime_1.jsx)(ComponentWrapper, { user: currentAccount, children: (0, jsx_runtime_1.jsx)(Component, {}) })) : ((0, jsx_runtime_1.jsx)(Component, {})) }, url));
            }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "*", element: (0, jsx_runtime_1.jsx)("p", { children: "There's nothing here: 404!" }) })] }));
};
exports.AppRoutes = AppRoutes;
