"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
// eslint-disable-next-line
var shared_1 = require("@space-start/shared");
var enum_1 = require("@web/enum");
var trpc_1 = require("@web/lib/trpc");
var routers_1 = require("@web/routers");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var date_fns_1 = require("date-fns");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var EventItem = function (_a) {
    var _b, _c;
    var event = _a.event, iconSrc = _a.iconSrc, title = _a.title, icon = _a.icon, refetchUserEvents = _a.refetchUserEvents;
    var id = (0, react_router_dom_1.useParams)().id;
    var _d = (0, react_1.useState)(false), isShowMoreActive = _d[0], setIsShowMoreActive = _d[1];
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var deleteEventWithVisit = trpc_1.trpc.userEvent.delete.useMutation();
    var hendlDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var type, entityId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    type = event.visitId ? shared_1.TypeHistoryEnum.VISIT : shared_1.TypeHistoryEnum.EVENT;
                    entityId = event.visitId || event.id;
                    return [4 /*yield*/, deleteEventWithVisit.mutateAsync({ id: entityId, type: type })];
                case 1:
                    _a.sent();
                    refetchUserEvents();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleNavigate = function () {
        var _a;
        if (event.type === shared_1.TypeHistoryEnum.VISIT) {
            navigateTo({
                pathname: (0, useNavigateTo_1.generateUrlParams)({
                    id: parseInt(id, 10),
                    activeTab: enum_1.UsersActiveTabs.VISIT,
                    state: enum_1.StateTabs.VIEW,
                    url: routers_1.usersUrl,
                    subId: (_a = event.visit) === null || _a === void 0 ? void 0 : _a.id
                })
            });
        }
    };
    var toggleShowMore = function () {
        setIsShowMoreActive(function (prev) { return !prev; });
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "history-item ".concat(isShowMoreActive ? '_showmore-active' : ''), children: [(0, jsx_runtime_1.jsx)("div", { className: "history-item__left", children: (0, jsx_runtime_1.jsx)("div", { className: "history-item__img", children: (0, jsx_runtime_1.jsx)("img", { src: iconSrc, alt: "".concat(title, " icon") }) }) }), (0, jsx_runtime_1.jsxs)("div", { className: "history-item__body", children: [(0, jsx_runtime_1.jsxs)("div", { className: "history-item__head", onClick: handleNavigate, children: [(0, jsx_runtime_1.jsx)("p", { className: "history-item__title", children: title }), (0, jsx_runtime_1.jsx)("span", { className: "history-item__date", children: (0, date_fns_1.format)(new Date(event.createdAt), 'MM/dd HH:mm') })] }), (0, jsx_runtime_1.jsxs)("div", { className: "history-item__content", children: [icon && ((0, jsx_runtime_1.jsx)("div", { className: "history-item__icon", children: (0, jsx_runtime_1.jsx)("img", { src: icon, alt: "icon" }) })), (0, jsx_runtime_1.jsx)("div", { className: "history-item__text", style: {
                                    height: isShowMoreActive ? 'auto' : '20px',
                                    overflow: isShowMoreActive ? 'visible' : 'hidden'
                                }, children: event.note })] }), (0, jsx_runtime_1.jsxs)("div", { className: "history-item__bottom", children: [(0, jsx_runtime_1.jsx)("div", { className: "history-item__icon", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/meneger.svg", alt: "Manager" }) }), (0, jsx_runtime_1.jsxs)("p", { className: "history-item__author", children: ["\u041C\u0435\u043D\u0435\u0434\u0436\u0435\u0440 ", (_b = event.account) === null || _b === void 0 ? void 0 : _b.firstName, " ", (_c = event.account) === null || _c === void 0 ? void 0 : _c.lastName] })] })] }), (0, jsx_runtime_1.jsxs)("div", { className: "history-item__actions", children: [(0, jsx_runtime_1.jsx)("button", { type: "button", className: "history-item__btn history-item__show", onClick: toggleShowMore, children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/full.svg", alt: "Show more" }) }), (0, jsx_runtime_1.jsx)("button", { className: "history-item__btn", onClick: hendlDelete, children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/deleteHistory.svg", alt: "Delete" }) })] })] }));
};
exports.default = EventItem;
