"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_hook_form_1 = require("react-hook-form");
var SchedulerForm = function (_a) {
    var onSubmit = _a.onSubmit, titleButton = _a.titleButton, user = _a.user;
    var handleSubmit = (0, react_hook_form_1.useForm)({
        defaultValues: {
            phone: (user === null || user === void 0 ? void 0 : user.phone) || '',
            email: (user === null || user === void 0 ? void 0 : user.email) || '',
            placeWork: (user === null || user === void 0 ? void 0 : user.placeWork) || '',
            street: (user === null || user === void 0 ? void 0 : user.street) || '',
            houseNumber: (user === null || user === void 0 ? void 0 : user.houseNumber) || '',
            city: (user === null || user === void 0 ? void 0 : user.city) || '',
            region: (user === null || user === void 0 ? void 0 : user.region) || '',
            zipCode: (user === null || user === void 0 ? void 0 : user.zipCode) || ''
        }
    }).handleSubmit;
    var onSubmitFun = function (data) {
        onSubmit(data);
    };
    return ((0, jsx_runtime_1.jsx)("button", { className: "btn btn-outline", onClick: handleSubmit(onSubmitFun), children: titleButton }));
};
exports.default = SchedulerForm;
