"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var enum_1 = require("@web/enum");
var utility_1 = require("@web/utility");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
var getRenderComponent = function (tab, state) {
    switch (state) {
        case enum_1.StateTabs.CREATE:
            return tab.create || tab.component;
        case enum_1.StateTabs.EDIT:
            return tab.edit || tab.component;
        default:
            return tab.component;
    }
};
var Tabs = function (_a) {
    var tabs = _a.tabs;
    var _b = (0, react_router_dom_1.useParams)(), activeTabKey = _b.activeTab, state = _b.state;
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var activeTab = tabs.find(function (tab) { return tab.id === activeTabKey; }) || tabs[0];
    var renderTab = getRenderComponent(activeTab, state);
    var _c = activeTab.isVisibility, isVisibility = _c === void 0 ? true : _c;
    return isVisibility ? ((0, jsx_runtime_1.jsxs)("div", { "data-tabs": "", "data-tabs-hash": "", className: "tabs _tab-init", "data-tabs-index": "0", children: [(0, jsx_runtime_1.jsx)("div", { className: "content__wrap", children: (0, jsx_runtime_1.jsx)("nav", { "data-tabs-titles": "", className: "tabs__navigation", children: tabs.map(function (_a, i) {
                        var label = _a.label, id = _a.id, pathname = _a.pathname;
                        return ((0, jsx_runtime_1.jsx)("button", { type: "button", className: (0, utility_1.classNames)('tabs__title', activeTab.id === id ? '_tab-active' : ''), "data-tabs-title": "", onClick: function () {
                                navigateTo({
                                    pathname: pathname
                                });
                            }, children: label }, i));
                    }) }) }), (0, jsx_runtime_1.jsx)("div", { "data-tabs-body": "", className: "tabs__content", children: renderTab() })] })) : (renderTab());
};
exports.default = Tabs;
