"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecializationEnum = exports.PurposeVisitEnum = exports.InteractionUserEnum = exports.AccountRoleEnum = exports.TypeHistoryEnum = exports.SexEnum = exports.DefaultResponseCodeEnum = exports.GmailAnalyticsEnum = void 0;
var GmailAnalyticsEnum;
(function (GmailAnalyticsEnum) {
    GmailAnalyticsEnum["EMAILS_SENT"] = "EMAILS_SENT";
    GmailAnalyticsEnum["EMAILS_RECEIVED"] = "EMAILS_RECEIVED";
})(GmailAnalyticsEnum || (exports.GmailAnalyticsEnum = GmailAnalyticsEnum = {}));
var DefaultResponseCodeEnum;
(function (DefaultResponseCodeEnum) {
    DefaultResponseCodeEnum["SUCCESS"] = "SUCCESS";
    DefaultResponseCodeEnum["ERROR"] = "ERROR";
})(DefaultResponseCodeEnum || (exports.DefaultResponseCodeEnum = DefaultResponseCodeEnum = {}));
var SexEnum;
(function (SexEnum) {
    SexEnum["MEN"] = "\u0427\u043E\u043B\u043E\u0432\u0456\u043A";
    SexEnum["WOMEN"] = "\u0416\u0456\u043D\u043A\u0430";
})(SexEnum || (exports.SexEnum = SexEnum = {}));
var TypeHistoryEnum;
(function (TypeHistoryEnum) {
    TypeHistoryEnum["VISIT"] = "VISIT";
    TypeHistoryEnum["CUSTOM"] = "CUSTOM";
    TypeHistoryEnum["EVENT"] = "EVENT";
})(TypeHistoryEnum || (exports.TypeHistoryEnum = TypeHistoryEnum = {}));
var AccountRoleEnum;
(function (AccountRoleEnum) {
    AccountRoleEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    AccountRoleEnum["DOCTOR"] = "DOCTOR";
    AccountRoleEnum["MANAGER"] = "MANAGER";
})(AccountRoleEnum || (exports.AccountRoleEnum = AccountRoleEnum = {}));
var InteractionUserEnum;
(function (InteractionUserEnum) {
    InteractionUserEnum["PHONE"] = "\u0422\u0435\u043B\u0435\u0444\u043E\u043D\u043D\u0438\u0439 \u0434\u0437\u0432\u0456\u043D\u043E\u043A";
    InteractionUserEnum["VIBER"] = "Viber";
    InteractionUserEnum["SMS"] = "\u041D\u0430\u0434\u0456\u0441\u043B\u0430\u0442\u0438 sms";
    InteractionUserEnum["EMAIL"] = "Email";
    InteractionUserEnum["VISIT"] = "\u0412\u0456\u0437\u0438\u0442";
    InteractionUserEnum["SERVISE"] = "\u041F\u043E\u0441\u043B\u0443\u0433\u0430";
})(InteractionUserEnum || (exports.InteractionUserEnum = InteractionUserEnum = {}));
var PurposeVisitEnum;
(function (PurposeVisitEnum) {
    PurposeVisitEnum["CONSULTATION"] = "\u041D\u0430 \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0456\u044E";
    PurposeVisitEnum["HELLP"] = "\u0414\u043B\u044F \u043D\u0430\u0434\u0430\u043D\u043D\u044F \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u0438";
    PurposeVisitEnum["TREATMENT"] = "\u041D\u0430 \u043B\u0456\u043A\u0443\u0432\u0430\u043D\u043D\u044F \u0456\u043D\u0448\u0435";
    PurposeVisitEnum["OTHER"] = "\u0406\u043D\u0448\u0435";
    PurposeVisitEnum["VISIT"] = "\u041C\u0435\u0442\u0430 \u0432\u0456\u0437\u0438\u0442\u0443";
})(PurposeVisitEnum || (exports.PurposeVisitEnum = PurposeVisitEnum = {}));
var SpecializationEnum;
(function (SpecializationEnum) {
    SpecializationEnum["Therapist"] = "\u0422\u0435\u0440\u0430\u043F\u0435\u0432\u0442";
    SpecializationEnum["Orthodontist"] = "\u041E\u0440\u0442\u043E\u0434\u043E\u043D\u0442";
    SpecializationEnum["Surgeon"] = "\u0425\u0456\u0440\u0443\u0440\u0433";
    SpecializationEnum["Periodontist"] = "\u041F\u0430\u0440\u043E\u0434\u043E\u043D\u0442\u043E\u043B\u043E\u0433";
    SpecializationEnum["Endodontist"] = "\u0415\u043D\u0434\u043E\u0434\u043E\u043D\u0442\u0438\u0441\u0442";
    SpecializationEnum["Implantologist"] = "\u0406\u043C\u043F\u043B\u0430\u043D\u0442\u043E\u043B\u043E\u0433";
    SpecializationEnum["Prosthetist"] = "\u041F\u0440\u043E\u0442\u0435\u0437\u0438\u0441\u0442";
})(SpecializationEnum || (exports.SpecializationEnum = SpecializationEnum = {}));
