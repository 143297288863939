"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var NotationsTable = function (_a) {
    var topNumbers = _a.topNumbers, bottomNumbers = _a.bottomNumbers, user = _a.user, formatNotation = _a.formatNotation;
    return ((0, jsx_runtime_1.jsxs)("tbody", { children: [(0, jsx_runtime_1.jsx)("tr", { children: topNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[0]) }, "".concat(num, "-top1")));
                }) }), (0, jsx_runtime_1.jsx)("tr", { children: topNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[1]) }, "".concat(num, "-top2")));
                }) }), (0, jsx_runtime_1.jsx)("tr", { children: topNumbers.map(function (num) { return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: num }, "".concat(num, "-number-top"))); }) }), (0, jsx_runtime_1.jsx)("tr", { children: bottomNumbers.map(function (num) { return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: num }, "".concat(num, "-number-bottom"))); }) }), (0, jsx_runtime_1.jsx)("tr", { children: bottomNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[0]) }, "".concat(num, "-bottom1")));
                }) }), (0, jsx_runtime_1.jsx)("tr", { children: bottomNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[1]) }, "".concat(num, "-bottom2")));
                }) })] }));
};
exports.default = NotationsTable;
