"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateTabs = exports.DoctorActiveTabs = exports.UsersActiveTabs = void 0;
var UsersActiveTabs;
(function (UsersActiveTabs) {
    UsersActiveTabs["PROFILE"] = "profile";
    UsersActiveTabs["CONTACT"] = "contact";
    UsersActiveTabs["VISIT"] = "visit";
    UsersActiveTabs["HISTORY"] = "history";
})(UsersActiveTabs || (exports.UsersActiveTabs = UsersActiveTabs = {}));
var DoctorActiveTabs;
(function (DoctorActiveTabs) {
    DoctorActiveTabs["PROFILE"] = "profile";
    DoctorActiveTabs["SCHEDULE"] = "schedule";
    DoctorActiveTabs["SECRET"] = "secret";
})(DoctorActiveTabs || (exports.DoctorActiveTabs = DoctorActiveTabs = {}));
var StateTabs;
(function (StateTabs) {
    StateTabs["VIEW"] = "view";
    StateTabs["EDIT"] = "edit";
    StateTabs["CREATE"] = "create";
})(StateTabs || (exports.StateTabs = StateTabs = {}));
