"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filtersToQuery = exports.useFilters = void 0;
var date_fns_1 = require("date-fns");
var react_router_dom_1 = require("react-router-dom");
function parseNumber(queryParams, key) {
    var orgUnit = queryParams.get(key);
    if (orgUnit) {
        return parseInt(orgUnit, 10);
    }
    return null;
}
function parseDate(queryParams, key) {
    var date = queryParams.get(key);
    if (date) {
        return new Date(date);
    }
    return null;
}
function useFilters() {
    var location = (0, react_router_dom_1.useLocation)();
    var queryParams = new URLSearchParams(location.search);
    return {
        account: parseNumber(queryParams, 'account'),
        user: parseNumber(queryParams, 'user'),
        dateFrom: parseDate(queryParams, 'datefrom') || (0, date_fns_1.subDays)((0, date_fns_1.startOfDay)(new Date()), 7),
        dateTo: parseDate(queryParams, 'dateto') || (0, date_fns_1.endOfDay)(new Date())
    };
}
exports.useFilters = useFilters;
function filtersToQuery(filters) {
    var query = new URLSearchParams();
    if (filters.account) {
        query.set('account', filters.account.toString());
    }
    if (filters.user) {
        query.set('user', filters.user.toString());
    }
    if (filters.dateFrom) {
        query.set('datefrom', (0, date_fns_1.format)(filters.dateFrom, 'yyyy-MM-dd'));
    }
    if (filters.dateTo) {
        query.set('dateto', (0, date_fns_1.format)(filters.dateTo, 'yyyy-MM-dd'));
    }
    return query.toString();
}
exports.filtersToQuery = filtersToQuery;
