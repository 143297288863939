"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_URL = exports.GOOGLE_CLIENT_ID = void 0;
var requiredEnvVars = ['REACT_APP_API_URL'];
requiredEnvVars.forEach(function (envVar) {
    if (!process.env[envVar]) {
        throw new Error("Missing required environment variable: ".concat(envVar));
    }
});
exports.GOOGLE_CLIENT_ID = process.env
    .REACT_APP_GOOGLE_CLIENT_ID;
exports.API_URL = process.env.REACT_APP_API_URL;
